import { AutomatitCarousel } from './automatit_carousel.m';

const heroCaro = {
	caro: document.querySelector('#home_hero_caro'),
	init: function() {

		const options = {
			element: this.caro,
			images: [
				{src: "/dist/images/background/homepage/img_hero_homepage.webp", alt: ""},
				// {src: "/dist/images/background/homepage/img_hero_homepage.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			showDots: false,
			useChevrons: false,
		};

		if(options.images.length === 1) {
			options.intervalTiming = false;
		}

		AutomatitCarousel(options);
	}
};

export const initHeroCaro = () => heroCaro.init();

export const featuresToggle = () => {
	const featureItems = document.querySelectorAll('.home_feature');
	if(window.innerWidth <= 750){
		[].forEach.call(featureItems, function(featureItem) {
			featureItem.addEventListener('click', e => {
				const clickedFeature = e.currentTarget;
				clickedFeature.classList.toggle('active');
			});
		});
	}
};