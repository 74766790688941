import { contactSubmit } from './contact.js';
import { initMap } from './map.js';
import { contactSearch } from './search.js';
import { locationsRates } from './locations.js';
import { navToggle } from './header.js';
import { featuresToggle} from './home.js';
import { facilityCaro, facilityMap, unitFilters } from './facility.js';
import { maps } from './nearby_map.js';
import { initReviewsCaro } from './reviews.js';
import { sizeGuidePages } from './size_guide.js';

navToggle.init();

unitFilters.init();
facilityCaro.init();
facilityMap.init();

maps.init();

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if (document.querySelector('#map')) {
	initMap();
}

contactSearch.init();
locationsRates.init();

if(document.querySelector('#size_guide')) {
	sizeGuidePages.init();
}

//For dropdown functionality on help center page.
if(document.querySelector('.tips_faqs_container')) {
	document.querySelectorAll(".help_cent_dropdown").forEach((item) => {
		item.addEventListener('click', function(){
			item.nextElementSibling.classList.toggle('open');
			item.classList.toggle('open');
		});
	});
}

if(document.querySelector('#home_features')) {
	featuresToggle();
}