/* eslint-disable no-undef */
export const contactSearch = (function(){
	let my = {};

	my.init = function(){
		if(document.querySelector('.search_form')){
			searchHelper().bindSearchEvent(document.querySelectorAll('.search_form'));
		}
	};

	return my;
})();