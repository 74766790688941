export const sizeGuidePages = (function(){
	let my = {};

	my.init = function () {
		
		function showEstimator() {
			document.getElementById('sizePageId').style.display = "none";
			document.getElementById('spacePageId').style.display = "block";
	
			if(window.innerWidth < 750) {
				document.getElementById('head_space_btn').classList.add('open');
				document.getElementById('head_size_btn').classList.remove('open');
			} else {
				document.getElementById('space_btn').classList.add('open');
				document.getElementById('size_btn').classList.remove('open');
			}
		}
	
		function showSizeGuide() {
			document.getElementById('spacePageId').style.display = "none";
			document.getElementById('sizePageId').style.display = "block";
	
			if(window.innerWidth < 750) {
				document.getElementById('head_size_btn').classList.add('open');
				document.getElementById('head_space_btn').classList.remove('open');
			} else {
				document.getElementById('size_btn').classList.add('open');
				document.getElementById('space_btn').classList.remove('open');
			}
		}
	
		document.querySelectorAll('#space_select').forEach((element) => {
			element.addEventListener('click', function() {
				showEstimator();
			});
		});
	
		if(location.href.split('#')[1] === 'estimator'){
			showEstimator();
		} else {
			showSizeGuide();
		}
		
		if(window.innerWidth < 750) {
			document.getElementById('head_size_btn').addEventListener('click', showSizeGuide);
			document.getElementById('head_space_btn').addEventListener('click', showEstimator);
		} else {
			document.getElementById('size_btn').addEventListener('click', showSizeGuide);
			document.getElementById('space_btn').addEventListener('click', showEstimator);
		}

	};
	return my;
})();